export class ValidationKit {
  static nameValidation(val) {
    if (!val?.length) return 'Поле не должно быть пустым';
    else return undefined;
  }

  static emailValidation(val) {
    if (!val?.length) return 'Поле не должно быть пустым';
    else if (!/^\S+@\S+\.\S+$/?.test(val)) return 'Неверный почтовый адрес';
    else return undefined;
  }

  static messageValidation(val) {
    if (!val?.length) return 'Поле не должно быть пустым';
    else return undefined;
  }

  static confidentialsValidation(val) {
    if (!val) return 'Необходимо принять условия конифденциальности данных';
    else return undefined;
  }

  static phoneValidation(val) {
    if (!val) return 'Поле не должно быть пустым';
    else return undefined;
  }
}

export class ValidationFactory {
  constructor({
                validationSchema = {}
              }) {
    this._schema = validationSchema;
  }

  validate(e) {
    const formVals = e.target?.elements;

    const errs = {};

    for (const [key, fn] of Object.entries(this._schema)) {
      if(typeof fn === 'function') {
        if (formVals[key]?.type === 'checkbox' && typeof formVals[key]?.checked === 'boolean') {
          errs[key] = fn(formVals[key]?.checked);
        } else {
          errs[key] = fn(formVals[key]?.value);
        }
      }
    }

    return Object.keys(errs).reduce((acc, cur) => {
      if(errs[cur] || typeof errs[cur] === 'boolean') {
        return {
          ...acc,
          [cur]: errs[cur]
        }
      }
      return acc;
    }, {});
  }
}

const {ValidationKit, ValidationFactory} = require('@scripts/form-validation/form-validation');

const nameId = 'supName';
const phoneId = 'supPhone';
const emailId = 'supEmail';
const confidId = 'isConfidentialsAgreed';
const formId = '.support-form__form';

document.addEventListener('DOMContentLoaded', () => {
  const form = document.querySelector(formId);
  const formErrorContainer = document.querySelector(`.support-form-form`);
  const formErrorMessageContainer = document.querySelector(`.support-form-form__error`);
  const popup = document.querySelector(`.popup`);

  const validate = new ValidationFactory({
    validationSchema: {
      [nameId]: ValidationKit.nameValidation,
      [emailId]: ValidationKit.emailValidation,
      [confidId]: ValidationKit.confidentialsValidation,
      [phoneId]: ValidationKit.nameValidation,
    }
  })

  form.addEventListener('submit', (e) => {
    e.preventDefault();

    const errors = validate.validate(e);
    const errMessages = Object.values(errors);

    if (errMessages.length) {
      formErrorMessageContainer.innerHTML = 'Ошибка: не указаны имя или электронная почта';
      formErrorContainer.classList.add('error');

      return;
    }

    const ajax = new Promise((resolve) => {
      setTimeout(resolve, 3000);
    })

    ajax.then(() => {
      popup.classList.add('active')

      const timerId = setTimeout(() => {
        popup.classList.remove('active');

        clearTimeout(timerId);
      }, 3000)
    })
  })
})
